import { ErrorBoundary } from '@app/components/error';
import NewsBanner from '@app/components/header/news';
import { useTheme } from '@app/components/hooks';
import NetworkStatus from '@app/components/network.status';
import { StyledAppDefaultMarginsClasses } from '@app/components/shared/app-main-container.styles';
import Footer from '@app/domains/common/components/Footer';
import withAmplitudeReport from '@app/domains/common/containers/withAmplitudeReport';
import { HEADER_HEIGHT_DOWN_SM, HEADER_HEIGHT_ONLY_SM, HEADER_HEIGHT_UP_MD } from '@app/domains/header/constants';
import Header from '@app/domains/header/containers/Header';
import { breakpoints } from '@app/utils/breakpoints';
import { useEnhancedEffect } from '@app/utils/utils';
import { CssBaseline } from '@mui/material';
import { GlobalStyles } from '@mui/styled-engine';
import clsx from 'clsx';
import { type ReactNode, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';

const OneSignal = lazy(() => import('@app/components/onesignal'));

//#region : Helpers

/**
 * Custom. Used by theme switch.
 */
// type ThemeAction = {
//     type: 'toggle-theme' | 'set-theme';
//     payload?: unknown;
// } & (
//     | {
//           type: 'toggle-theme';
//       }
//     | {
//           type: 'set-theme';
//           payload: string;
//       }
// );

/**
 * Custom. Used by theme switch.
 */
// function themeReducer(state: Theme, action: ThemeAction) {
//     switch (action.type) {
//         case 'toggle-theme':
//             return state.name === 'light' ? MaterialDarkTheme : MaterialLightTheme;
//         case 'set-theme':
//             return action.payload === 'light' ? MaterialLightTheme : MaterialDarkTheme;
//     }
//
//     return state;
// }

/**
 * Custom.
 *
 * Returns 'light' theme if the client is not a browser.
 * In case it is browser, return stored theme, if there.
 */
// const getStoredTheme = () => {
//     if (!isBrowser()) {
//         return MaterialLightTheme;
//     }
//
//     let theme: Theme | null = null;
//
//     const themeName = localStorage.getItem('theme');
//
//     switch (themeName) {
//         case 'light':
//             theme = MaterialLightTheme;
//             break;
//         case 'dark':
//             theme = MaterialDarkTheme;
//             break;
//     }
//
//     return theme;
// };
//#endregion : Helpers

//#region : Main component

// const bannerProps = {
//     innerClassName: '!max-w-[840px] m-auto px-[20px] sm:px-[30px] lg:px-0',
// };

export type LayoutProps = {
    showFooter?: boolean;
    showNavItems?: boolean;
    showNewsBanner?: boolean;
    defaultMargins?: boolean;
    headerStickyBoundary?: string | number;
    children?: ReactNode;
};

/**
 * Custom.
 *
 * This component handles the main layout of the site.
 */
function Layout({ defaultMargins, showFooter, showNewsBanner, showNavItems, children }: LayoutProps) {
    //#region : Variables, functions and api calls

    //#region : Footer ad
    // const [removeFooterAd, setRemoveFooterAd] = useState(false);

    // const handleOnFooterAdClose = useCallback(() => {
    //     setRemoveFooterAd(true);
    // }, []);
    //#endregion: Footer ad

    //#region : Check user login required
    // const { user, init, login } = useAuth();

    // const location = useLocation();

    // const loginRequired = useMemo(() => {
    //     if (location.pathname.startsWith('/auth')) {
    //         return false;
    //     }

    //     if (init && !user) {
    //         return true;
    //     }

    //     return !init || !user?.vip;
    // }, [init, location.pathname, user]);
    //#endregion : Check user login required

    const theme = useTheme();

    useEnhancedEffect(() => {
        if (!process.env.SSR) {
            document.documentElement.setAttribute(
                'class',
                clsx({
                    dark: theme.palette.mode === 'dark',
                })
            );
        }
    }, [theme]);

    //#endregion : Variables, functions and api calls

    return (
        <>
            <Helmet
                meta={[
                    {
                        name: 'theme-color',
                        content: theme.palette.mode === 'light' ? '#ffffff' : '#313131',
                    },
                ]}
            ></Helmet>
            <CssBaseline enableColorScheme />
            <GlobalStyles
                styles={{
                    body: {
                        '& button:focus': {
                            outline: 'none',
                        },
                        '& :focus-visible': {
                            outline: 'none !important',
                        },
                        overflowX: 'hidden',
                    },
                }}
            />
            <div
                id="layout"
                css={{
                    backgroundColor: `${theme.palette.primary.main} !important`,
                }}
                className="min-h-screen"
            >
                <NetworkStatus>
                    <Header showNavItems={showNavItems} />
                    <Suspense>
                        <OneSignal />
                    </Suspense>
                    <main id="app">
                        {showNewsBanner && <NewsBanner className="bg-blue-bg-light" count={2} />}
                        <div
                            className={clsx(
                                {
                                    [StyledAppDefaultMarginsClasses]: defaultMargins,
                                },
                                'grid'
                            )}
                            css={{
                                '&>*': {
                                    minWidth: '0px',
                                },
                                [breakpoints.upMd]: {
                                    minHeight: `calc(100vh - ${HEADER_HEIGHT_UP_MD}px)`,
                                },
                                [breakpoints.onlySm]: {
                                    minHeight: `calc(100vh - ${HEADER_HEIGHT_ONLY_SM}px)`,
                                },
                                [breakpoints.downSm]: {
                                    minHeight: `calc(100vh - ${HEADER_HEIGHT_DOWN_SM}px)`,
                                },
                            }}
                        >
                            <ErrorBoundary>{children}</ErrorBoundary>
                        </div>
                    </main>
                </NetworkStatus>
                {showFooter && <Footer />}
            </div>
        </>
    );
}
//#endregion : Main component

export default withAmplitudeReport(Layout);
