import { DecimalValue } from '@app/_proto/Protos/types';
import { DoubleValue } from '@app/_proto/google/protobuf/wrappers';
import { decimalToNumber } from '@app/libs/utils';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

export const IsCrawler =
    typeof navigator !== 'undefined'
        ? /bot|crawler|spider|crawling|Chrome-Lighthouse/i.test(navigator.userAgent)
        : false;

/**
 * Custom type.
 */
type ColorSchemeName = 'no-preference' | 'dark' | 'light';

/**
 * Custom type.
 */
type ColorScheme = {
    query: MediaQueryList | null;
    scheme: ColorSchemeName;
} | null;

/**
 * Custom.
 *
 * Array of color scheme names.
 */
const COLOR_SCHEMES: ColorSchemeName[] = ['no-preference', 'dark', 'light'];

/**
 * Custom.
 *
 * Site's default color scheme.
 */
const DEFAULT_TARGET_COLOR_SCHEME: ColorSchemeName = 'light';

/**
 * Custom function.
 *
 * Returns default color scheme if the provided scheme is invalid
 * or is set to no-preference.
 */
function resolveTargetColorScheme(scheme: ColorSchemeName) {
    if (!COLOR_SCHEMES.includes(scheme) || scheme === 'no-preference') {
        return DEFAULT_TARGET_COLOR_SCHEME;
    }

    return scheme;
}

/**
 * Custom function.
 *
 * Get user's system/browser setting of dark mode preference, if available.
 */
function getCurrentColorScheme(): ColorScheme {
    if (!isBrowser()) {
        return { query: null, scheme: DEFAULT_TARGET_COLOR_SCHEME };
    }

    const QUERIES = {};

    return (() => {
        //Check user prefers any of the color schemes supported by the site
        //Check the css media feature `prefers-color-scheme` using media query.
        for (const scheme of COLOR_SCHEMES) {
            // eslint-disable-next-line no-prototype-builtins
            const query = QUERIES.hasOwnProperty(scheme)
                ? QUERIES[scheme]
                : (QUERIES[scheme] = matchMedia(`(prefers-color-scheme: ${scheme})`));

            if (query.matches) return { query, scheme };
        }

        return null;
    })();
}

/**
 * Custom hook.
 *
 * Returns 'useLayoutEffect' for browser and 'useEffect' for non-browser.
 */
export const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * Custom function.
 *
 * Generates random number between the given range.
 */
export function randomNumberBetween(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Custom function.
 *
 * Generates hashcode for the given string.
 */
export function getHashCode(str: string) {
    if (!str) {
        return 0;
    }

    var hash = 0,
        i,
        chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}

/**
 * Custom function.
 *
 * Checks if the client is a browser or not.
 */
export function isBrowser() {
    return typeof window !== 'undefined';
}

/**
 * Custom hook.
 *
 * Checks if the client is a browser or not.
 */
export function useIsBrowser() {
    const [browser, setBrowser] = useState(false);

    const result = isBrowser();

    useEnhancedEffect(() => {
        setBrowser(result);
    }, [result]);

    return browser;
}

/**
 * Custom hook.
 *
 * Returns true if the color scheme provide in the parameter matches
 * user's system/browser color scheme preference i.e. light/dark/no-preference.
 * Otherwise returns false.
 *
 * Also handles any change in the user's system / browser color scheme preference.
 */
export function useColorScheme(targetColorScheme: ColorSchemeName) {
    /** Custom. Keeps track of whether the component is mounted or not */
    const isMounted = useRef(false);

    /** Custom. Stores user's system/browser color scheme preference with media query object.  */
    const colorScheme = useRef<ColorScheme>();

    /**
     * Custom.
     *
     * Will have the colorScheme provided in the parameter, if it is valid.
     * Otherwise it will fallback to site's default color scheme.
     */
    const targetScheme = useMemo(() => resolveTargetColorScheme(targetColorScheme), [targetColorScheme]);

    /** Stores user's system/browser color scheme preference. */
    const [scheme, setColorScheme] = useState(() => {
        const { scheme } = (colorScheme.current = getCurrentColorScheme()!);

        return scheme;
    });

    //Handles any change in the user's system / browser color scheme preference.
    useEffect(() => {
        const { query } = colorScheme.current || {};

        /** Custom. User's system / browser color scheme preference change listener and handler. */
        function schemeChangeHandler(evt: MediaQueryListEvent) {
            if (!evt.matches) {
                query!.removeEventListener('change', schemeChangeHandler);

                const { query: newQuery, scheme } = (colorScheme.current = getCurrentColorScheme()!);

                isMounted.current && setColorScheme(scheme);

                newQuery?.addEventListener('change', schemeChangeHandler);
            }
        }

        query?.addEventListener?.('change', schemeChangeHandler);

        isMounted.current = true;

        //Remove the event listener when component dismounts
        return () => {
            const { query } = colorScheme.current || {};

            query?.removeEventListener?.('change', schemeChangeHandler);

            isMounted.current = false;
        };
    }, []);

    return scheme === targetScheme;
}

/**
 * Custom.
 *
 * Default config values for currency display formatting.
 */
const DEFAULT_CURRENCY_FORMAT = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
});

const DEFAULT_RATING_FORMAT = new Intl.NumberFormat(['en-US'], {
    style: 'percent',
});

export function formatRatingForDisplay(amount: number | DoubleValue | undefined) {
    if (amount === undefined) {
        return 'Not yet rated';
    }

    if (DoubleValue.is(amount)) {
        return DEFAULT_RATING_FORMAT.format(amount.value);
    }

    return DEFAULT_RATING_FORMAT.format(amount);
}

/**
 * Custom function.
 *
 * Formats given amount in USD for display with currency symbol.
 */
export function formatAmountForDisplay(amount: number | DecimalValue | undefined): string {
    if (amount === undefined) {
        return DEFAULT_CURRENCY_FORMAT.format(0);
    }

    if (DecimalValue.is(amount)) {
        return DEFAULT_CURRENCY_FORMAT.format(decimalToNumber(amount));
    }

    return DEFAULT_CURRENCY_FORMAT.format(amount);
}

// Taken from https://github.com/vercel/next.js/blob/e3133984685ee7c6712b23ef04a9495551e06c6a/packages/next/client/image.tsx#L42

export function getIntForImg(x: unknown): number | undefined {
    if (typeof x === 'number') {
        return x;
    }
    if (typeof x === 'string') {
        return Number.parseInt(x, 10);
    }
    return undefined;
}

const VALID_LAYOUT_VALUES = ['fill', 'fixed', 'intrinsic', 'responsive', undefined] as const;
export type LayoutValue = (typeof VALID_LAYOUT_VALUES)[number];

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
const imageSizes = [16, 32, 48, 64, 96, 128, 192, 256, 384];

const allSizes = [...deviceSizes, ...imageSizes];
deviceSizes.sort((a, b) => a - b);
allSizes.sort((a, b) => a - b);

export function getImageWidths(
    width: number | undefined,
    layout: LayoutValue,
    sizes: string | undefined
): { widths: number[]; kind: 'w' | 'x' } {
    if (sizes && (layout === 'fill' || layout === 'responsive')) {
        // Find all the "vw" percent sizes used in the sizes prop
        const viewportWidthRe = /(^|\s)(1?\d?\d)vw/g;
        const percentSizes: number[] = [];
        for (let match; (match = viewportWidthRe.exec(sizes)); match) {
            percentSizes.push(Number.parseInt(match[2]));
        }
        if (percentSizes.length) {
            const smallestRatio = Math.min(...percentSizes) * 0.01;
            return {
                widths: allSizes.filter(s => s >= deviceSizes[0] * smallestRatio),
                kind: 'w',
            };
        }
        return { widths: allSizes, kind: 'w' };
    }
    if (typeof width !== 'number' || layout === 'fill' || layout === 'responsive') {
        return { widths: deviceSizes, kind: 'w' };
    }

    const widths = Array.from(
        new Set(
            // > This means that most OLED screens that say they are 3x resolution,
            // > are actually 3x in the green color, but only 1.5x in the red and
            // > blue colors. Showing a 3x resolution image in the app vs a 2x
            // > resolution image will be visually the same, though the 3x image
            // > takes significantly more data. Even true 3x resolution screens are
            // > wasteful as the human eye cannot see that level of detail without
            // > something like a magnifying glass.
            // https://blog.twitter.com/engineering/en_us/topics/infrastructure/2019/capping-image-fidelity-on-ultra-high-resolution-devices.html
            [width, width * 2 /*, width * 3*/].map(w => allSizes.find(p => p >= w) || allSizes[allSizes.length - 1])
        )
    );

    return { widths, kind: 'x' };
}

export type GenImgAttrsData = {
    src: string;
    unoptimized: boolean;
    layout: LayoutValue;
    width?: number;
    quality?: number;
    sizes?: string;
    format?: string;
};

export type GenImgAttrsResult = {
    src: string;
    srcSet: string | undefined;
    sizes: string | undefined;
};

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

export const getAbsoluteUrl = (path: string) => {
    const url = new URL(process.env.VITE_REACT_APP_SITE!);
    url.pathname = path;

    return url.toString();
};

export const imgLoader = ({
    src,
    width,
    quality,
    format,
}: {
    src: string;
    width?: number;
    quality?: number;
    format?: string;
}) => {
    if (!src) {
        return '';
    }

    const baseUrl = 'https://www.wuxiaworld.com';
    const params = ['fit=scale-down'];

    if (width) {
        params.push(`width=${width}`);
    }

    if (quality) {
        params.push(`quality=${quality}`);
    }

    if (format) {
        params.push(`format=${format}`);
    } else {
        params.push('format=auto');
    }

    const paramsString = params.join(',');

    return src;
    // return `${baseUrl}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

export function useGenerateImgAttrs({
    src,
    unoptimized,
    layout,
    width,
    quality,
    sizes,
    format,
}: GenImgAttrsData): GenImgAttrsResult {
    const getValue = useCallback(() => {
        const value: GenImgAttrsResult = {
            src: imgLoader({ src, quality, width }),
            srcSet: undefined,
            sizes: undefined,
        };

        if (!unoptimized && !layout) {
            const { widths, kind } = getImageWidths(width, layout, sizes);
            const last = widths.length - 1;

            value.sizes = !sizes && kind === 'w' ? '100vw' : sizes;
            value.src = imgLoader({ src, quality, width: widths[last] });
            value.srcSet = widths
                .map((w, i) => `${imgLoader({ src, quality, format, width: w })} ${kind === 'w' ? w : i + 1}${kind}`)
                .join(', ');
        }

        return value;
    }, [format, layout, quality, sizes, src, unoptimized, width]);

    const [value, setValue] = useState<GenImgAttrsResult>(() => getValue());

    useEnhancedEffect(() => {
        setValue(getValue());
    }, [getValue]);

    return value;
}
export function isNotNull<T>(obj: T | null | undefined): obj is T {
    return !!obj;
}
