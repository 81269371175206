// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/pricing.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "../google/protobuf/wrappers";
import { Timestamp } from "../google/protobuf/timestamp";
import { Duration } from "../google/protobuf/duration";
import { DecimalValue } from "./types";
import { Any } from "../google/protobuf/any";
import { Int32Value } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetActivePricingRequest
 */
export interface GetActivePricingRequest {
    /**
     * @generated from protobuf field: int32 seriesId = 1;
     */
    seriesId: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetActivePricingResponse
 */
export interface GetActivePricingResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.PricingModel items = 1;
     */
    items: PricingModel[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetActivePricingRotationsRequest
 */
export interface GetActivePricingRotationsRequest {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value seriesId = 1;
     */
    seriesId?: Int32Value;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetActivePricingRotationsRequest.PricingTypeFilter type = 2;
     */
    type: GetActivePricingRotationsRequest_PricingTypeFilter;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.GetActivePricingRotationsRequest.PricingTypeFilter
 */
export enum GetActivePricingRotationsRequest_PricingTypeFilter {
    /**
     * @generated from protobuf enum value: All = 0;
     */
    All = 0,
    /**
     * @generated from protobuf enum value: Free = 1;
     */
    Free = 1,
    /**
     * @generated from protobuf enum value: Karma = 2;
     */
    Karma = 2,
    /**
     * @generated from protobuf enum value: WaitToUnlock = 3;
     */
    WaitToUnlock = 3
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetActivePricingRotationsResponse
 */
export interface GetActivePricingRotationsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GetActivePricingRotationsResponse.PricingRotation items = 1;
     */
    items: GetActivePricingRotationsResponse_PricingRotation[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetActivePricingRotationsResponse.PricingRotation
 */
export interface GetActivePricingRotationsResponse_PricingRotation {
    /**
     * @generated from protobuf field: google.protobuf.Any object = 1;
     */
    object?: Any;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PricingModelRotation rotation = 2;
     */
    rotation?: PricingModelRotation;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.FreePricingModel
 */
export interface FreePricingModel {
    /**
     * @generated from protobuf field: customTypes.DecimalValue freeChapters = 1;
     */
    freeChapters?: DecimalValue;
    /**
     * @generated from protobuf field: bool requiresLogin = 2;
     */
    requiresLogin: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value anonymousChaptersCount = 3;
     */
    anonymousChaptersCount?: Int32Value;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.KarmaPricingModel
 */
export interface KarmaPricingModel {
    /**
     * @generated from protobuf field: customTypes.DecimalValue freeChapters = 1;
     */
    freeChapters?: DecimalValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.WaitToUnlockPricingModel
 */
export interface WaitToUnlockPricingModel {
    /**
     * @generated from protobuf field: google.protobuf.Duration waitTime = 1;
     */
    waitTime?: Duration;
    /**
     * @generated from protobuf field: customTypes.DecimalValue freeChapters = 2;
     */
    freeChapters?: DecimalValue;
    /**
     * @generated from protobuf field: int32 lastHoldBack = 3;
     */
    lastHoldBack: number;
    /**
     * @generated from protobuf field: int32 unlocksPerWaitTime = 4;
     */
    unlocksPerWaitTime: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.PricingModelRotation
 */
export interface PricingModelRotation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp startTime = 2;
     */
    startTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endTime = 3;
     */
    endTime?: Timestamp;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.PricingModel
 */
export interface PricingModel {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 seriesId = 2;
     */
    seriesId: number;
    /**
     * @generated from protobuf field: bool active = 3;
     */
    active: boolean;
    /**
     * @generated from protobuf oneof: pricing
     */
    pricing: {
        oneofKind: "free";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.FreePricingModel free = 4;
         */
        free: FreePricingModel;
    } | {
        oneofKind: "karma";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.KarmaPricingModel karma = 5;
         */
        karma: KarmaPricingModel;
    } | {
        oneofKind: "waitToUnlock";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.WaitToUnlockPricingModel waitToUnlock = 6;
         */
        waitToUnlock: WaitToUnlockPricingModel;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.PricingModelRotation rotations = 9;
     */
    rotations: PricingModelRotation[];
    /**
     * @generated from protobuf field: google.protobuf.StringValue currentRotationId = 10;
     */
    currentRotationId?: StringValue;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePricingRequest$Type extends MessageType<GetActivePricingRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetActivePricingRequest", [
            { no: 1, name: "seriesId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetActivePricingRequest
 */
export const GetActivePricingRequest = new GetActivePricingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePricingResponse$Type extends MessageType<GetActivePricingResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetActivePricingResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PricingModel }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetActivePricingResponse
 */
export const GetActivePricingResponse = new GetActivePricingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePricingRotationsRequest$Type extends MessageType<GetActivePricingRotationsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetActivePricingRotationsRequest", [
            { no: 1, name: "seriesId", kind: "message", T: () => Int32Value },
            { no: 2, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.GetActivePricingRotationsRequest.PricingTypeFilter", GetActivePricingRotationsRequest_PricingTypeFilter] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetActivePricingRotationsRequest
 */
export const GetActivePricingRotationsRequest = new GetActivePricingRotationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePricingRotationsResponse$Type extends MessageType<GetActivePricingRotationsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetActivePricingRotationsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetActivePricingRotationsResponse_PricingRotation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetActivePricingRotationsResponse
 */
export const GetActivePricingRotationsResponse = new GetActivePricingRotationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePricingRotationsResponse_PricingRotation$Type extends MessageType<GetActivePricingRotationsResponse_PricingRotation> {
    constructor() {
        super("wuxiaworld.api.v2.GetActivePricingRotationsResponse.PricingRotation", [
            { no: 1, name: "object", kind: "message", T: () => Any },
            { no: 2, name: "rotation", kind: "message", T: () => PricingModelRotation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetActivePricingRotationsResponse.PricingRotation
 */
export const GetActivePricingRotationsResponse_PricingRotation = new GetActivePricingRotationsResponse_PricingRotation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreePricingModel$Type extends MessageType<FreePricingModel> {
    constructor() {
        super("wuxiaworld.api.v2.FreePricingModel", [
            { no: 1, name: "freeChapters", kind: "message", T: () => DecimalValue },
            { no: 2, name: "requiresLogin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "anonymousChaptersCount", kind: "message", T: () => Int32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.FreePricingModel
 */
export const FreePricingModel = new FreePricingModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KarmaPricingModel$Type extends MessageType<KarmaPricingModel> {
    constructor() {
        super("wuxiaworld.api.v2.KarmaPricingModel", [
            { no: 1, name: "freeChapters", kind: "message", T: () => DecimalValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.KarmaPricingModel
 */
export const KarmaPricingModel = new KarmaPricingModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WaitToUnlockPricingModel$Type extends MessageType<WaitToUnlockPricingModel> {
    constructor() {
        super("wuxiaworld.api.v2.WaitToUnlockPricingModel", [
            { no: 1, name: "waitTime", kind: "message", T: () => Duration },
            { no: 2, name: "freeChapters", kind: "message", T: () => DecimalValue },
            { no: 3, name: "lastHoldBack", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "unlocksPerWaitTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.WaitToUnlockPricingModel
 */
export const WaitToUnlockPricingModel = new WaitToUnlockPricingModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PricingModelRotation$Type extends MessageType<PricingModelRotation> {
    constructor() {
        super("wuxiaworld.api.v2.PricingModelRotation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startTime", kind: "message", T: () => Timestamp },
            { no: 3, name: "endTime", kind: "message", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.PricingModelRotation
 */
export const PricingModelRotation = new PricingModelRotation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PricingModel$Type extends MessageType<PricingModel> {
    constructor() {
        super("wuxiaworld.api.v2.PricingModel", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "seriesId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "free", kind: "message", oneof: "pricing", T: () => FreePricingModel },
            { no: 5, name: "karma", kind: "message", oneof: "pricing", T: () => KarmaPricingModel },
            { no: 6, name: "waitToUnlock", kind: "message", oneof: "pricing", T: () => WaitToUnlockPricingModel },
            { no: 9, name: "rotations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PricingModelRotation },
            { no: 10, name: "currentRotationId", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.PricingModel
 */
export const PricingModel = new PricingModel$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Pricing
 */
export const Pricing = new ServiceType("wuxiaworld.api.v2.Pricing", [
    { name: "GetActivePricing", options: {}, I: GetActivePricingRequest, O: GetActivePricingResponse },
    { name: "GetActivePricingRotations", options: {}, I: GetActivePricingRotationsRequest, O: GetActivePricingRotationsResponse }
]);
